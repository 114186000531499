import { useState } from 'react';
import { UnstyledButton, Menu, Group } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { dealerList } from '~/modules/investment-consultant/orderBox/MutipleDealerQuickFuturesOrderBox/Function/dealerInfoList';
export function PickDealerMenu({ handleClick, selectedDealer, }) {
    const [opened, setOpened] = useState(false);
    const items = dealerList.map(item => (<Menu.Item onClick={() => handleClick(item)} key={item.label}>
      {item.label}
    </Menu.Item>));
    return (<styleds.container>
      <styleds.title>選擇券商</styleds.title>
      <Menu onOpen={() => setOpened(true)} onClose={() => setOpened(false)} radius='md' width='target' withinPortal zIndex={99999}>
        <Menu.Target>
          <UnstyledButton data-expanded={opened || undefined} css={css `
              ${flex.h.crossCenter}
              transition: background-color 150ms ease;
            `}>
            <Group>
              <span css={css `
                  font-size: 14px;
                  color: #fafafa;
                  height: 22px;
                `}>
                {selectedDealer.label}
              </span>
            </Group>
            <IconChevronDown css={css `
                transition: transform 150ms ease;
                transform: rotate(0deg);
                [data-expanded] & {
                  transform: rotate(180deg);
                }
              `} stroke={1.5}/>
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>{items}</Menu.Dropdown>
      </Menu>
    </styleds.container>);
}
const styleds = {
    container: styled.div `
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
  `,
    title: styled.div `
    font-size: 14px;
    margin: auto 0;
  `,
};
